import * as TYPES from "./blogCategoryActionTypes";
const defaultState = {
  categories: [],
  selCategory: {}
};

export default function category(state = defaultState, action) {
  switch (action.type) {
    case TYPES.ALL_BLOG_CATOGRIES:
      return {
        ...state,
        categories: action.value
      };
    case TYPES.SEL_BLOG_CATOGRIES:
      return {
        ...state,
        selCategory: action.value
      };
    default:
      return state;
  }
}
