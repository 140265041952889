import { combineReducers } from "redux";
import app from "./app/appReducers";
import auth from "./auth/authReducers";
import blog from "./blog/blogReducers";
import blogCategory from "./blogCategory/blogCategoryReducers";
import blogImage from "./blogImage/blogImageReducers";
import reseller from "./reseller/resellerReducers";

export default combineReducers({
  appData: app,
  authData: auth,
  blogData: blog,
  blogImageData: blogImage,
  blogCategoryData: blogCategory,
  resellerData: reseller
});
