import * as TYPES from "./blogActionTypes";
const defaultState = {
  count: 0,
  blogs: [],
  selBlog: {},
  commentCount: 0,
  comments: []
};

export default function blog(state = defaultState, action) {
  switch (action.type) {
    case TYPES.ALL_BLOG:
      return {
        ...state,
        blogs: action.value
      };
    case TYPES.SEL_BLOG:
      return {
        ...state,
        selBlog: action.value
      };
    case TYPES.ALL_BLOG_COUNT:
      return {
        ...state,
        count: action.value
      };
    case TYPES.SEL_BLOG_COMMENTS_COUNT:
      return {
        ...state,
        commentCount: action.value
      };
    case TYPES.SEL_BLOG_COMMENTS:
      return {
        ...state,
        comments: action.value
      };
    default:
      return state;
  }
}
