import * as TYPES from "./blogImageActionTypes";
const defaultState = {
  count: 0,
  images: [],
  selImage: {}
};

export default function category(state = defaultState, action) {
  switch (action.type) {
    case TYPES.BLOG_COUNT:
      return {
        ...state,
        count: action.value
      };
    case TYPES.ALL_BLOG_IMAGES:
      return {
        ...state,
        images: action.value
      };
    case TYPES.SEL_BLOG_IMAGES:
      return {
        ...state,
        selCategory: action.value
      };
    default:
      return state;
  }
}
