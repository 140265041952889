import * as TYPES from "./resellerActionTypes";
const defaultState = {
  count: 0,
  resellers: [],
  selReseller: {},
  resellerBusiness: []
};

export default function blog(state = defaultState, action) {
  switch (action.type) {
    case TYPES.ALL_RESELLER:
      return {
        ...state,
        resellers: action.value
      };
    case TYPES.SEL_RESELLER:
      return {
        ...state,
        selReseller: action.value
      };
    case TYPES.ALL_RESELLER_COUNT:
      return {
        ...state,
        count: action.value
      };
    case TYPES.RESELLER_BUSINESS:
      return {
        ...state,
        resellerBusiness: action.value
      };
    default:
      return state;
  }
}
