import _get from "lodash/get";
import * as TYPES from "./authActionTypes";
const defaultState = {
  authBusy: false,
  preCheck: false,
  token: null,
  profile: null,
  business: null,
  reseller: null,
  countLimit: 10,
  accessTokens: [],

  // ROLES
  isAdmin: false,
  isPartner: false,
  ishosSupport: false
};

export default function auth(state = defaultState, action) {
  switch (action.type) {
    case TYPES.PROFILE:
      const userRole = _get(action, "value.role", {});

      return {
        ...state,
        countLimit: _get(action, "value.loadsperpage", 10),
        profile: action.value,
        isAdmin: userRole === "stadmin",
        isPartner: userRole === "partner",
        isReseller: userRole === "reseller",
        ishosSupport: userRole === "hosSupport"
      };
    case TYPES.FIREBASE_TOKEN:
      return {
        ...state,
        firebaseToken: action.value
      };
    case TYPES.DRIVER:
      return {
        ...state,
        driver: action.value
      };
    case TYPES.BUSINESS:
      return {
        ...state,
        business: action.value
      };
    case TYPES.RESELLER:
      return {
        ...state,
        reseller: action.value
      };
    case TYPES.AUTH_TOKEN:
      return {
        ...state,
        token: action.value
      };
    case TYPES.AUTH_BUSY:
      return {
        ...state,
        authBusy: action.value
      };
    case TYPES.PRE_CHECK:
      return {
        ...state,
        preCheck: action.value
      };
    case TYPES.USERS:
      return {
        ...state,
        users: action.value
      };
    case TYPES.ACCESS_TOKENS:
      return {
        ...state,
        accessTokens: action.value
      };
    default:
      return state;
  }
}
